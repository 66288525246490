import Card from './card'
// import CardContent from './card-content'
// import CardFooter from './card-footer'

// Card.Content = CardContent
// Card.Footer = CardFooter
// Card.Body = CardContent
// Card.Actions = CardFooter

export default Card
