import React from 'react'
import CardFooter from './card-footer'
import CardContent from './card-content'
import type { SxStyleProp } from '@theme-ui/core'
import { useDripsyTheme, View, Text } from 'dripsy'
import { Variants } from '../types'
import { pickChild } from '../../helpers/collections'

interface Props {
  hoverable?: boolean
  shadow?: boolean
  sx?: SxStyleProp
  width?: number | string
  variant?: Variants
  children?: React.ReactNode
  /**
   * If true, our item is loading and we should show skeletons for any fields.
   */
  placeholder?: boolean
  type?: 'filled' | 'faded' | 'outline'
}

type NativeAttrs = Omit<Partial<React.ComponentProps<typeof View>>, keyof Props>
export type CardProps = Props & NativeAttrs

// https://github.com/geist-org/react/tree/master/components/card
// TODO finish props, hoverable, variants
const Card = ({
  children,
  hoverable = false,
  sx = {},
  shadow = false,
  variant = 'default',
  placeholder = false,
  type,
  width = '100%',
  ...props
}: CardProps) => {
  const { colors } = useDripsyTheme().theme
  const getBgColor = () => {
    if (type === 'outline' || variant === 'default') return 'background'
    if (type === 'faded') {
      const lighter = colors?.[`${variant}Lighter`]
      if (lighter) {
        return lighter
      }
      return `${colors?.[variant]}20`
    }
    return colors?.[variant]
  }

  const getBorderColor = () => {
    if (variant === 'default') return colors?.muted4

    return colors?.[variant]
  }
  const bg = getBgColor()
  const borderColor = getBorderColor()

  const [withoutTextChildren, textChildren] = pickChild(children, Text)

  return (
    <View
      sx={{
        bg,
        borderColor,
        width,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        overflow: 'hidden',
        ...sx,
      }}
      {...props}
    >
      {children}
    </View>
  )
}

type MemoCardComponent<P = {}> = React.NamedExoticComponent<P> & {
  Footer: typeof CardFooter
  // Actions: typeof CardFooter
  Content: typeof CardContent
  // Body: typeof CardContent
}

Card.Footer = CardFooter
Card.Content = CardContent

export default Card
// export default (React.memo(Card) as unknown) as MemoCardComponent<CardProps>
