/** @jsx jsx */
import React from 'react'
import { jsx } from '@theme-ui/core'
import type { View } from 'dripsy'

type Props = {
  height?: number | (number | null)[]
  width?: number | (number | null)[]
} & React.ComponentProps<typeof View>

export default function SpacerWeb(props: Props) {
  const { sx = {}, width, height = width ? undefined : [2, 3] } = props

  return <div sx={{ pb: height, pr: width, ...sx }} />
}
