import Press from '@beatgig/components/press'
import { TextInput, View, useDripsyTheme } from 'dripsy'
import React from 'react'
import { Platform } from 'react-native'
import type { SxStyleProp } from '@theme-ui/core'
import InputLabel from './input-label'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import Loading from '../loading'
import type { TextInput as InputType } from 'react-native'
import useTheme from '@beatgig/theme/use-theme'

type Props = {
  hasErrored?: boolean
  isHovered?: boolean
  isFocused?: boolean
  borderColor?: string | string[]
  containerSx?: SxStyleProp
  pressSx?: SxStyleProp
  labelSx?: SxStyleProp
  label?: string
  labelRight?: string
  clearable?: boolean
  loading?: boolean
  onClear?: (string: '') => void
} & React.ComponentProps<typeof TextInput>

const Input = React.forwardRef<InputType, Props>(function Input(
  {
    sx = {},
    containerSx = {},
    pressSx = {},
    // hasTouched = false,
    isHovered,
    isFocused,
    editable = true,
    label,
    labelRight,
    hasErrored,
    labelSx,
    clearable = false,
    value,
    onChangeText,
    loading,
    onClear,
    placeholderTextColor = 'mutedText',
    ...props
  },
  ref
) {
  const [focused, setFocused] = React.useState(false)

  const { colors } = useTheme()

  const borderRadius = (sx as any)?.borderRadius ?? 3

  // else if (hasErrored) borderColor = 'danger'

  const placeholderColor = colors[placeholderTextColor] || placeholderTextColor

  return (
    <Press sx={{ cursor: 'default', ...pressSx }}>
      {({ hovered }) => {
        const hasFocus = typeof isFocused === 'undefined' ? focused : isFocused
        const hasHover = typeof isHovered === 'undefined' ? hovered : isHovered

        let borderColor = 'mutedText'
        if (!editable) {
        } else if (hasFocus) {
          borderColor = 'primary'
        } else if (hasHover) {
          borderColor = 'text'
        }
        if (hasErrored) {
          borderColor = 'error'
        }
        return (
          <View
            sx={{
              borderColor,
              transitionProperty: 'border-color',
              transitionDuration: '.45s',
              borderRadius,
              borderWidth: 1,
              overflow: 'hidden',
              flexDirection: 'row',
              bg: 'background',
              ...containerSx,
            }}
          >
            {!!label && <InputLabel sx={labelSx}>{label}</InputLabel>}
            <View sx={{ flex: 1 }}>
              <TextInput
                {...props}
                value={value}
                onChangeText={onChangeText}
                placeholderTextColor={placeholderColor}
                // ref={ref}
                editable={editable}
                onFocus={(e) => {
                  if (!editable) return
                  setFocused(true)
                  props.onFocus?.(e)
                }}
                onBlur={(e) => {
                  setFocused(false)
                  props.onBlur?.(e)
                }}
                ref={ref as any}
                sx={{
                  // bg: !editable ? undefined : 'muted',
                  fontSize: 3,
                  color: 'text',
                  px: 2,
                  py: 2,
                  // my: 1,
                  outlineWidth: Platform.OS === 'web' ? 0 : undefined,
                  outlineColor: 'transparent',
                  cursor: editable ? 'text' : 'not-allowed',
                  flex: 1,
                  borderRadius,
                  ...sx,
                }}
              />
              <View
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  mr: 2,
                  justifyContent: 'center',
                  opacity: loading ? 1 : 0,
                }}
                pointerEvents="none"
              >
                <Loading />
              </View>
            </View>
            {clearable && (
              <Press
                onPress={() => {
                  onChangeText?.('')
                  onClear?.('')
                }}
                sx={{ opacity: value ? 1 : 0, justifyContent: 'center', px: 2 }}
              >
                <Ionicons color={colors?.text} name="close-outline" size={30} />
              </Press>
            )}
            {!!labelRight && (
              <InputLabel sx={labelSx} right>
                {labelRight}
              </InputLabel>
            )}
          </View>
        )
      }}
    </Press>
  )
})

// Input = React.memo(Input)

export default Input
