/** @jsx jsx */
import { jsx } from '@theme-ui/core'
import { useDripsyTheme } from 'dripsy'
import { HoverTooltipProps } from './types'
import viewResetStyle from '@beatgig/helpers/view-reset-style'
import { DripsyTheme } from '@beatgig/theme'
import { useFont } from '@beatgig/theme/use-font'

export default function HoverTooltip(props: HoverTooltipProps) {
  const {
    placement = 'right',
    anchor = 'top',
    children,
    textSx = {},
    text,
    onPress,
    bg = 'background',
    width = 150,
    borderColor = 'border',
    visible = false,
    sx = {},
  } = props

  const { body } = useFont()

  const transitionStyle = (active: boolean) => {
    const transformBy = 5
    const style = {
      opacity: active ? 1 : 0,
      '&, & *': {
        pointerEvents: active ? 'auto' : 'none',
      },
    }
    if (placement === 'bottom') {
      return {
        ...style,
        transform: `translateY(${active ? 0 : -transformBy}px)`,
      }
    } else if (placement === 'top') {
      return {
        ...style,
        // transform: [{ translateY: active ? 0 : transformBy }],
        transform: `translateY(${active ? 0 : transformBy}px)`,
      }
    } else if (placement === 'right') {
      return {
        ...style,
        // transform: [{ translateX: active ? 0 : -transformBy }],
        transform: `translateX(${active ? 0 : -transformBy}px)`,
      }
    } else if (placement === 'left') {
      return {
        ...style,
        // transform: [{ translateX: active ? 0 : -transformBy }],
        transform: `translateX(${active ? 0 : transformBy}px)`,
      }
    }
  }

  const tooltipSx: Record<string, any> = {}
  if (placement === 'bottom') {
    tooltipSx.top = '100%'
    tooltipSx[anchor || 'left'] = 0
  } else if (placement === 'right') {
    tooltipSx.left = '100%'
    tooltipSx[anchor || 'top'] = 0
  } else if (placement === 'top') {
    tooltipSx.bottom = '100%'
    tooltipSx[anchor || 'left'] = 0
  } else if (placement === 'left') {
    tooltipSx.right = '100%'
    tooltipSx[anchor || 'top'] = 0
  }

  return (
    <div
      sx={{
        ...viewResetStyle,
        // zIndex: 1000,
        cursor: onPress ? 'pointer' : 'inherit',
        ...sx,
        '& .tip': {
          ...transitionStyle(visible),
        },
        '&:hover .tip, &:focus .tip, &:active .tip': {
          ...transitionStyle(true),
        },
      }}
      onClick={(e) => onPress?.(e as any)}
    >
      {children}
      {!!text && (
        <div
          className="tip"
          sx={{
            position: 'absolute',
            bg: bg as any,
            borderRadius: 3,
            borderColor,
            borderWidth: 2,
            transitionProperty: 'opacity, transform',
            transitionDuration: '0.25s',
            transitionTimingFunction: 'ease-in-out',
            width,
            maxWidth: '100vw',
            p: 2,
            zIndex: 1000,
            borderStyle: 'solid',
            fontSize: 2,
            lineHeight: '1.2em',
            fontFamily: body.default,
            // color: 'text',
            ...tooltipSx,
            ...textSx,
          }}
        >
          {text}
        </div>
      )}
    </div>
  )
}
