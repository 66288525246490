import type { Variants, Filling } from '../components/types'
import { useDripsyTheme } from 'dripsy'
import Color from 'color'

type Props = {
  variant: Variants
  filling: Filling
}

export default function useGetColors({ variant, filling }: Props) {
  const { colors } = useDripsyTheme().theme

  const getBgColor = (): string => {
    if (filling === 'outlined') return 'transparent'
    if (filling === 'faded') {
      let color = colors?.[variant]
      if (!color && variant === 'default') {
        if (colors?.text) {
          color = colors?.text as string
        }
      }
      if (color && typeof color === 'string') {
        // copied from https://github.com/react-navigation/react-navigation/blob/main/packages/drawer/src/views/DrawerItem.tsx#L131
        return Color(color).alpha(0.1).rgb().string()
      }
    }

    const color = colors?.[variant]

    if (!color && variant === 'default' && colors?.text) {
      return colors?.text as string
    }

    return color as string
  }

  const bg = getBgColor()

  const getTextColor = (): string => {
    let color = colors?.text as string

    // first, set defaults for filled background
    if (Color(bg).isLight()) {
      // if the background is too light
      if (Color(colors?.background).isDark()) {
        color = colors?.background as string
      } else if (Color(colors?.text).isDark()) {
        color = colors?.text as string
      }
    } else if (Color(bg).isDark()) {
      // otherwise, if it's too dark
      if (Color(colors?.text).isLight()) {
        color = colors?.text as string
      } else if (Color(colors?.background).isLight()) {
        color = colors?.background as string
      }
    }
    // if (
    //   // TODO change this to a color library that checks if it's light or dark
    //   variant === 'primary' ||
    //   variant === 'success' ||
    //   variant === 'callout'
    // ) {
    //   color = colors?.background
    // }
    if (filling === 'outlined') {
      if (colors?.[variant]) {
        color = colors?.[variant] as string
      } else if (variant === 'default' && colors?.text) {
        color = colors?.text as string
      }
    } else if (filling === 'faded') {
      color = (colors?.[variant] as string) ?? colors?.text
      // make sure there is enough contrast w/ the bg
      // TODO should we just darken this, or what?
      // if (Color(bg).isDark() && Color(color).isDark()) {
      //   if (Color(colors?.text).isLight()) {
      //     color = colors?.text as string
      //   } else if (Color(colors?.background).isLight()) {
      //     color = colors?.background as string
      //   }
      // } else if (Color(bg).isLight() && Color(color).isLight()) {
      //   if (Color(colors?.text).isDark()) {
      //     color = colors?.text as string
      //   } else if (Color(colors?.background).isDark()) {
      //     color = colors?.background as string
      //   }
      // }
    }
    return color
  }
  const color = getTextColor()

  const getBorderColor = (): string => {
    if (filling === 'outlined') return color
    if (filling === 'faded') return color
    if (filling === 'filled') return bg

    return bg
  }

  const borderColor = getBorderColor()

  return {
    bg,
    color,
    borderColor,
  }
}
